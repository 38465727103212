<template>
  <section class="search--profile">
    <img
      class="profile--picture"
      :src="!owner ? '/img/placeholder.png' : owner.photo_profile_user"
      :alt="owner.fullname"
    />
    <div class="name">
      {{ !owner ? '' : owner.fullname }}
    </div>
    <div class="about">
      {{ !owner ? '' : owner.about_me }}
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'search-profile',
  computed: {
    ...mapState({
      owner: (state) => state.v2.profile.owner,
    }),
  },
};
</script>
